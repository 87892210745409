import React, { useState, useEffect, useRef, useContext, useCallback } from "react";


import useTickets from "../../hooks/useTickets";

import { toast } from "react-toastify";
import "./dashboard.css"
import TableAttendantsStatus from "../../components/Dashboard/TableAttendantsStatus";
import { isArray } from "lodash";

import useDashboard from "../../hooks/useDashboard";
import useCompanies from "../../hooks/useCompanies";

import { isEmpty } from "lodash";
import moment from "moment";
import DashboardHeader from "../../components/DashboardHeader";
import DashboardFilter from "../../components/DashboardFilter";
import DashboardGraphs from "../../components/DashboardGraphs";
import useStyles from "./theme";
import { useMediaQuery, useTheme } from "@material-ui/core";
import DashboardSubheader from "../../components/DashboardSubheader";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import DashboardContext from "../../context/DashboardContext";

const Dashboard = () => {
  const theme = useTheme();

  const classes = useStyles(theme);
   const {
            counters,
            setCounters,
            attendants,
            setAttendants,
            lastTickets,
            setLastTickets,
            allTicketsCount,
            setAllTicketsCount,
            filterType,
            setFilterType,
            reportCount,
            setReportCounts,
            period,
            setPeriod,
            companyDueDate,
            setCompanyDueDate,
            dateTo,
            setDateTo,
            dateFrom,
            setDateFrom,
            serviceQuality,
            setServiceQuality
   } = useContext(DashboardContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { find, getReport, getServiceQuality } = useDashboard();
  const { finding } = useCompanies();
  const { user } = useContext(AuthContext);
  const isMobile = useMediaQuery("(max-width: 900px)");
  
  if(companyDueDate && false) {
    console.log("");
  }

  const mimicFetchData = useCallback(fetchData, []);
  const mimicFetchServiceQuality = useCallback(fetchServiceQuality, []);

  useEffect(() => {
    async function firstLoad() {
      await mimicFetchData();
      await mimicFetchServiceQuality();
    }
    setTimeout(() => {
      firstLoad();
    }, 1000);
    if(isMobile) {
      history.push("/tickets")
    }
  }, [mimicFetchData, mimicFetchServiceQuality, isMobile]);

  async function fetchServiceQuality() {
    if (companyId) {
      try {
        const data = await getServiceQuality(companyId);
        setServiceQuality(data.serviceQualityPercentage);
      } catch (error) {
        console.error("Erro ao buscar qualidade do serviço:", error);
      }
    }
  }

  async function handleChangePeriod(value) {
    setPeriod(value);
  }

  async function handleChangeFilterType(value) {
    setFilterType(value);
    if (value === 1) {
      setPeriod(0);
    } else {
      setDateFrom("");
      setDateTo("");
    }
  }

  async function fetchData() {
    setLoading(true);

    let params = {};

    if (period > 0) {
      params = {
        days: period,
      };
    }

    if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
      params = {
        ...params,
        date_from: moment(dateFrom).format("YYYY-MM-DD"),
      };
    }

    if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
      params = {
        ...params,
        date_to: moment(dateTo).format("YYYY-MM-DD"),
      };
    }

    if (Object.keys(params).length === 0) {
      toast.error("Parametrize o filtro");
      setLoading(false);
      return;
    }

    const data = await find(user.super ? {} : params);
    setCounters(user.super ? data : data.counters);
    setAllTicketsCount(data)
    setLastTickets(data.tickets);

    if (isArray(data.attendants) || data.onlineUsers) {
      setAttendants(user.super ? data.onlineUsers : data.attendants);
    } else {
      setAttendants([]);
    }

    setLoading(false);
  }

  async function fetchCompaniesData() {
    await loadCompanies();
    const data = await find();
    setLastTickets(data?.tickets || []);
    setAllTicketsCount(data ? data : [])
  }
  async function fetchReport() {
    if(lastTickets?.length === 0 || !reportCount) {
      const data = await getReport({
        searchParam: "",
        whatsappId: [],
        users: [],
        queueIds: [],
        status: [],
        categoryIds: [],
        dateFrom: dateFrom,
        dateTo: dateTo,
        page: 1,
        pageSize: 20,
      });

      setReportCounts(data);
    }
  };
  const mimicFetchCompaniesData = useCallback(fetchCompaniesData, [])
  const mimicFetchReport = useCallback(fetchReport, [])
  useEffect(() => {
    mimicFetchCompaniesData();
    mimicFetchReport()
    
  }, [mimicFetchCompaniesData, mimicFetchReport]);

  const companyId = localStorage.getItem("companyId");
  
  const loadCompanies = async () => {
    setLoading(true);
    try {
      const companiesList = await finding(companyId);
      setCompanyDueDate(moment(companiesList.dueDate).format("DD/MM/yyyy"));
    } catch (e) {
      console.log("🚀 Console Log : e", e);
    }
    setLoading(false);
  };

  const date = useRef(new Date().toISOString());
  const { tickets } = useTickets({ date: date.current });

  return (
    <div>
        <div className={classes.container}>
          <div style={{display: "flex", gap:"2rem", flexDirection: "column"}}>
            <DashboardHeader isSuper={user.super} classes={classes} attendants={attendants} counters={counters} serviceQuality={serviceQuality} />
            {!user.super &&<DashboardSubheader classes={classes} attendants={attendants} counters={counters} reportCount={allTicketsCount}/>}
          </div>
          <DashboardGraphs
              classes={classes}
              isSuper={user.super}
              theme={theme}
              attendants={attendants}
              counters={counters}
              lastTickets={lastTickets}
              serviceQuality={serviceQuality} // Passa a qualidade do serviço como prop
              tickets={tickets}
            />
          {!user.super && <DashboardFilter
            filterType={filterType}
            dateFrom={dateFrom}
            dateTo={dateTo}
            setDateFrom={setDateFrom}
            classes={classes}
            setDateTo={setDateTo}
            period={period}
            loading={loading}
            handleChangePeriod={handleChangePeriod}
            handleChangeFilterType={handleChangeFilterType}
            fetchData={fetchData}
          />}
          {!user.super && <div className={"cmp-dashboard__attendants-table"}>
            {attendants.length ? (
              <TableAttendantsStatus
                counters={counters}
                attendants={attendants}
                loading={loading}
                classes={classes}
              />
            ) : null}
          </div>}
        </div>
      
    </div>
  );
};

export default Dashboard;
