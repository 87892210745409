import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PersonIcon from "@material-ui/icons/Person";
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import NightsStayIcon from '@material-ui/icons/NightsStay';

import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import NotificationsVolume from "../components/NotificationsVolume";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";
import toastError from "../errors/toastError";
import AnnouncementsPopover from "../components/AnnouncementsPopover";

import { socketConnection } from "../services/socket";
import ChatPopover from "../pages/Chat/ChatPopover";

import ColorModeContext from "../layout/themeContext";
import useStyles from "./theme"
import themeDefault from "../static/themeDefault";
import api from "../services/api";

const LoggedInLayout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 900px)");
  const classes = useStyles();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const initialMenu = localStorage.getItem("menuOpenDrawer") ? localStorage.getItem("menuOpenDrawer") : "";
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(isMobile ? false : initialMenu === "open");
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const { user } = useContext(AuthContext);
  const { colorMode } = useContext(ColorModeContext);
  const [darkMode, setDarkModeOn] = useState(localStorage.getItem("preferredTheme") !== "light");
  const [volume, setVolume] = useState(localStorage.getItem("volume") || 1);
  const [companyDueDate, setCompanyDueDate] = useState();
  const history = useHistory();

  useEffect(() => {
    if (document.body.offsetWidth < 950) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");

    const finding = async (id) => {
      const { data } = await api.request({
        url: `/companies/${id}`,
        method: "GET",
      });
      return data;
    };

    const getDueDate = async (companyId) => {
      const companiesList = await finding(companyId);
      setCompanyDueDate(moment(companiesList.dueDate).format("DD/MM/yyyy"));
    };

    getDueDate(companyId);

    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-auth`, (data) => {
      if (data.user.id === +userId) {
        toastError("Sua conta foi acessada em outro computador.");
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 1000);
      }
      if (data.user.profile === "user") {
        history.current.push("/tickets");
      }
    });

    socket.emit("userStatus");
    const interval = setInterval(() => {
      socket.emit("userStatus");
    }, 1000 * 60 * 5);

    return () => {
      socket.disconnect();
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setDrawerOpen(false);
      setDrawerVariant("temporary");
    }
  }, [isMobile])


  const handleProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseProfileMenu = () => {
    setAnchorEl(null);

    setMenuOpen(false);
  };


  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseProfileMenu();
  };

  const handleClickLogout = () => {
    sessionStorage.clear();
  
    localStorage.setItem("pageTheme", themeDefault);
    colorMode.setPageTheme(themeDefault);
  
    handleCloseProfileMenu();
  
    handleLogout();
  };
  

  const drawerClose = () => {
    if (document.body.offsetWidth < 950) {
      setDrawerOpen(false);
    }
  };

  const toggleColorMode = () => {
    colorMode.toggleColorMode();
    setMenuOpen(false);
    setDarkModeOn(localStorage.getItem("preferredTheme") === "light")
  }

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <div className={`cmp-menu ${classes.root} ${darkMode ? "cmp-darkmode" : ""} ${drawerOpen ? `cmp-menu--menu-open--connections` : ""}`}>
      <Drawer
        variant={drawerVariant}
        className={drawerOpen ? `cmp-menu--menu-open ${classes.drawerPaper}` : classes.drawerPaperClose}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            isMobile ? drawerOpen && classes.drawerPaperClose : !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}>
          <IconButton className={classes.iconDrawer} onClick={() => setDrawerOpen(!drawerOpen)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <Divider />
        <List className={`cmp-menu__list ${classes.containerWithScroll}`}>
          <MainListItems isMobile={isMobile} drawerClose={drawerClose} collapsed={!drawerOpen} />
        </List>
        <Divider />
      </Drawer>

      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
        style={{
          background: theme.palette.primary.mainGradient,
        }}
      >
        <Toolbar variant="dense" className={`${classes.toolbar} ${drawerOpen ? "cmp-menu__top-header--open" : "cmp-menu__top-header"} `}>
          <IconButton
            edge="start"
            variant="contained"
            aria-label="open drawer"
            onClick={() => {
              localStorage.setItem("menuOpenDrawer", drawerOpen ? "closed" : "open");
              setDrawerOpen(!drawerOpen)
            }}
            className={clsx(
              classes.menuButton,
              drawerOpen && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            component="h2"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >

          </Typography>

          {user.id && <NotificationsPopOver volume={volume} />}

          <AnnouncementsPopover />

          <ChatPopover />
          <IconButton onClick={toggleColorMode}>
            {theme.mode === 'dark' ? (
              <>

                <WbSunnyIcon style={{ color: "white" }} />
              </>
            ) : (
              <>
                <NightsStayIcon style={{ color: "white" }} />
              </>
            )}
          </IconButton>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleProfileMenu}
              variant="contained"
              style={{ color: "white" }}
            >
              <PersonIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={menuOpen}
              onClose={handleCloseProfileMenu}
            >
              <MenuItem>
                <span style={{ fontFamily: "Poppins", fontSize: "14px" }}>
                  Sua assinatura: {companyDueDate}
                </span>
              </MenuItem>
              <MenuItem onClick={handleOpenUserModal}><span style={{ fontFamily: "Poppins", fontSize: "14px" }}>{i18n.t("mainDrawer.appBar.user.profile")} </span></MenuItem>
              {<NotificationsVolume
                setVolume={setVolume}
                volume={volume}
              />}
              <MenuItem onClick={handleClickLogout}> <span style={{ fontFamily: "Poppins", fontSize: "14px" }}>{i18n.t("mainDrawer.appBar.user.logout")}</span> </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        {children ? children : null}
      </main>
    </div>
  );
};

export default LoggedInLayout;