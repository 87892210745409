import React, { useState, useRef, useEffect, useContext, useCallback } from "react";

import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { socketConnection } from "../../services/socket";

import useSound from "use-sound";

import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import HeadsetIcon from "@material-ui/icons/Headset";

import TicketListItem from "../TicketListItemCustom";
import useTickets from "../../hooks/useTickets";
import alertSound from "../../assets/sound.mp3";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	tabContainer: {
		overflowY: "auto",
		maxHeight: 350,
		...theme.scrollbarStyles,
	},
	popoverPaper: {
		width: "100%",
		maxWidth: 350,
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(1),
		[theme.breakpoints.down("sm")]: {
			maxWidth: 270,
		},
	},
	noShadow: {
		boxShadow: "none !important",
	},
}));

const NotificationsPopOver = (volume) => {
	const classes = useStyles();

	const history = useHistory();
	const { user } = useContext(AuthContext);
	const userRef = useRef(user);
	const ticketIdUrl = +history.location.pathname.split("/")[2];
	const ticketIdRef = useRef(ticketIdUrl);
	const anchorEl = useRef();
	const [isOpen, setIsOpen] = useState(false);
	const [notifications, setNotifications] = useState([]);

	const [showPendingTickets, setShowPendingTickets] = useState(false);

	const [, setDesktopNotifications] = useState([]);

	const { tickets } = useTickets({ withUnreadMessages: "true" });

	const [play] = useSound(alertSound, volume);
	const soundAlertRef = useRef();

	const historyRef = useRef(history);

	useEffect(() => {
		const fetchSettings = async () => {
			try {

				if (userRef.allTicket === "enable") {
					setShowPendingTickets(true);
				}
			} catch (err) {
				toastError(err);
			}
		}

		fetchSettings();
	}, []);

	useEffect(() => {
		soundAlertRef.current = play;

		if (!("Notification" in window)) {
			console.log("This browser doesn't support notifications");
		} else {
			Notification.requestPermission();
		}
	}, [play]);

	useEffect(() => {
		const processNotifications = () => {
			if (showPendingTickets) {
				setNotifications(tickets);
			} else {
				const newNotifications = tickets.filter(ticket => ticket.status !== "pending");

				setNotifications(newNotifications);
			}
		}

		processNotifications();
	}, [tickets, showPendingTickets]);

	useEffect(() => {
		ticketIdRef.current = ticketIdUrl;
	}, [ticketIdUrl]);

	const PAGE_TAB_TITLE = {
		"connect": "Connect Flow",
		"tarifando": "Tarifando Telecom"
	}
	const originalTitle = PAGE_TAB_TITLE[process.env.REACT_APP_THEME_LOGO];

	const originalTitleRef = useRef(originalTitle);
	const handleNotifications = useCallback(
		(data) => {
			const { message, contact, ticket } = data;

			const options = {
				body: `${message.body} - ${format(new Date(), "HH:mm")}`,
				icon: contact.urlPicture,
				tag: ticket.id,
				renotify: true,
			};

			const notification = new Notification(
				`${i18n.t("tickets.notification.message")} ${contact.name}`,
				options
			);

			notification.onclick = (e) => {
				e.preventDefault();
				window.focus();
				history.current.push(`/tickets/${ticket.uuid}`);
			};

			setDesktopNotifications((prevState) => {
				const notificationIndex = prevState.findIndex(
					(n) => n.tag === notification.tag
				);
				if (notificationIndex !== -1) {
					prevState[notificationIndex] = notification;
					return [...prevState];
				}
				return [notification, ...prevState];
			});

			const flashTitle = () => {
				let isOriginalTitle = true;

				const interval = setInterval(() => {
					document.title = isOriginalTitle ? "❗Nova Mensagem" : (originalTitleRef.current);
					isOriginalTitle = !isOriginalTitle;
				}, 1000);

				const stopFlashing = () => {
					clearInterval(interval);
					document.title = originalTitle.current;
					window.removeEventListener("focus", stopFlashing);
				};

				window.addEventListener("focus", stopFlashing);

				window.addEventListener("blur", () => {
					isOriginalTitle = false;
					document.title = originalTitle.current;
				});
			};

			flashTitle();
			soundAlertRef.current();
		},
		[setDesktopNotifications]
	);

	useEffect(() => {
		const socket = socketConnection({ companyId: user.companyId, userId: user.id });
		socket.on("connect", () => socket.emit("joinNotification"));

		socket.on(`company-${user.companyId}-ticket`, data => {
			if (data.action === "updateUnread" || data.action === "delete") {
				setNotifications(prevState => {
					const ticketIndex = prevState.findIndex(t => t.id === data.ticketId);
					if (ticketIndex !== -1) {
						prevState.splice(ticketIndex, 1);
						return [...prevState];
					}
					return prevState;
				});

				setDesktopNotifications(prevState => {
					const notfiticationIndex = prevState.findIndex(
						n => n.tag === String(data.ticketId)
					);
					if (notfiticationIndex !== -1) {
						prevState[notfiticationIndex].close();
						prevState.splice(notfiticationIndex, 1);
						return [...prevState];
					}
					return prevState;
				});
			}
		});

		socket.on(`company-${user.companyId}-appMessage`, data => {
			if (
				data.action === "create" && !data.message.fromMe &&
				(data.ticket.status !== "pending") &&
				(!data.message.read || data.ticket.status === "pending") &&
				(data.ticket.userId === user?.id || !data.ticket.userId) &&
				(user?.queues?.some(queue => (queue.id === data.ticket.queueId)) || !data.ticket.queueId)
			) {
				setNotifications(prevState => {
					const ticketIndex = prevState.findIndex(t => t.id === data.ticket.id);
					if (ticketIndex !== -1) {
						prevState[ticketIndex] = data.ticket;
						return [...prevState];
					}
					return [data.ticket, ...prevState];
				});

				const shouldNotNotificate =
					(data.message.ticketId === ticketIdRef.current &&
						document.visibilityState === "visible") ||
					(data.ticket.userId && data.ticket.userId !== user?.id) ||
					data.ticket.isGroup;

				if (shouldNotNotificate) return;

				handleNotifications(data);
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [user, showPendingTickets, handleNotifications]);

	const handleClick = () => {
		setIsOpen(prevState => !prevState);
	};

	const handleClickAway = () => {
		setIsOpen(false);
	};

	const NotificationTicket = ({ children }) => {
		return <div onClick={handleClickAway}>{children}</div>;
	};

	return (
		<>
			<IconButton
				onClick={handleClick}
				ref={anchorEl}
				aria-label="Open Notifications"
				color="inherit"
				style={{ color: "white" }}
			>
				<Badge overlap="rectangular" badgeContent={notifications.length} color="secondary">
					<HeadsetIcon style={{ color: "white" }} />
				</Badge>
			</IconButton>
			<Popover
				disableScrollLock
				open={isOpen}
				anchorEl={anchorEl.current}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				classes={{ paper: classes.popoverPaper }}
				onClose={handleClickAway}
			>
				<List dense className={classes.tabContainer}>
					{notifications.length === 0 ? (
						<ListItem>
							<ListItemText>{i18n.t("notifications.noTickets")}</ListItemText>
						</ListItem>
					) : (
						notifications.map(ticket => (
							<NotificationTicket key={ticket.id}>
								<TicketListItem setHideMessages={(any) => { }} setMobileUserOpen={(any) => { }} ticket={ticket} />
							</NotificationTicket>
						))
					)}
				</List>
			</Popover>
		</>
	);
};

export default NotificationsPopOver;